#Address {
  padding: 20px;
  .conatiner {
    padding: 20px;
  }
  .addressContainer {
    padding: 12px 15px;
    border-radius: 10px;
    margin: 0px 3px 10px;
    background-color: #fff;
    box-shadow: var(--SHADOW);
  }
  .addressEditIcon {
    background-color: var(--PRIMARY_BLUE);
    border-radius: 50px;
    padding: 1px 5px;
    margin-left: 3px;
  }
  .addressField {
    margin-top: 10px !important;
  }
  .dropField {
    padding-bottom: 8px;
  }
}
