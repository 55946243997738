#Splash {
  min-height: var(--MIN_HEIGHT);
  background-color: var(--PRIMARY_BLUE);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .logo {
    width: 200px;
    margin-bottom: 40px;
  }
}