#WebAddress {
  padding: 20px;
  .conatiner {
    padding: 20px;
  }
  .addMainContainer {
    max-width: 450px;
    margin: 50px auto;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }
  .addressContainer {
    padding: 12px 15px;
    border-radius: 10px;
    margin: 0px 3px 10px;
    background-color: #fff;
    box-shadow: var(--SHADOW);
  }
  .addressEditIcon {
    background-color: var(--PRIMARY_BLUE);
    border-radius: 50px;
    padding: 1px 5px;
    margin-left: 3px;
  }
  .addressField {
    margin-top: 10px !important;
  }
  .dropField {
    padding-bottom: 8px;
  }
}
