#WebProducts {
  padding-bottom: 10px;
  background-color: white;

  .slider-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
  }

  .slick-slide {
    display: inline-block;
  }

  .slick-track {
    display: flex !important;
    flex-direction: row !important;
  }

  .category-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    box-sizing: border-box;

    .circle {
      min-width: 150px;
      height: 150px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      color: black;
      transition: all 0.3s ease;
      z-index: 1;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
    }

    .category-name {
      font-size: 14px;
      font-weight: var(--BOLD_FONT) !important;
    }

    .category-count {
      font-size: 12px;
      color: gray;
    }
  }
  .category-circle {
    border: 2px solid black;
  }
  .category-circle-open {
    border: 3px solid #1d89cc;
    box-shadow: 2px 4px 8px gray;
  }
  .custom-arrow {
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    background-color: #1d89cc;
    border: 1px solid #1d89cc;
    border-radius: 50%;
    padding: 12px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    &:hover {
      background-color: black;
      color: white;
    }
  }

  .left-arrow {
    left: -30px !important;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  .right-arrow {
    right: -40px !important;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  .productsMainContainer {
    display: flex;
  }
  .verticalFilters {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .catSection {
    margin-top: 5px;
    padding: 12px 20px;
    background-color: #bbbbbb;
  }
  .productContainer {
    margin: 7px;
    margin-top: 15px;
    width: calc((100% / 4) - 30px);
  }

  @media screen and (max-width: 1024px) {
    .productContainer {
      width: calc((100% / 3) - 30px);
    }
  }

  @media screen and (max-width: 768px) {
    .productContainer {
      width: calc((100% / 2) - 30px);
    }
  }

  @media screen and (max-width: 480px) {
    .productContainer {
      width: calc(100% - 30px);
    }
  }
  .productImg {
    // width: -webkit-fill-available;
    width: 100%;
    height: 300px;
    border-radius: 7px;
    object-fit: contain;
    background-color: #fff;
    box-shadow: var(--SHADOW);
  }
  .poductDescription {
    padding-top: 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
  }
  .poductOffersDescription {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    text-overflow: ellipsis;
  }
  .productColor {
    padding: 30px;
    margin: 10px 4px;
    border-radius: 60%;
    box-shadow: 0px 0px 3px 0px gray;
  }
  .addProductConatiner {
    position: absolute;
    right: 15px;
    bottom: -15px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--SHADOW);
    background-color: var(--LIGHT_COLOR);
    width: calc((var(--WIDTH) / 3) - 50px);
  }
  .buttonWrapper {
    width: 100px;
    height: auto;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: #fff;
    border-radius: 5px;
    transition: transform 100ms ease-in-out, opacity 100ms ease-in-out; /* Smooth transitions */
    cursor: pointer;
    padding: 5px;
    box-shadow: var(--SHADOW);
  }

  .buttonWrapper:hover {
    transform: translate(-50%, -40%);
    opacity: 0.9;
  }
  .productIcon {
    display: block;
    color: var(--PRIMARY_BLUE);
  }
  .changeProductConatiner {
    width: 60px;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
  }
  .viewCart {
    margin: 10px;
    padding: 20px;
    border-radius: 15px;
    background-color: var(--PRIMARY_BLUE);
  }

  .productCartContainer {
    padding: 15px;
    background-color: #fff;
  }
  .placeOrderBtn {
    width: 150px;
    padding: 10px 15px;
    border-radius: 10px !important;
    margin-top: -5px;
  }
  .productErr {
    font-size: 10px !important;
    margin: 2px 0px 0px 12px;
    color: var(--ERROR);
  }
  .divider {
    border-bottom: 1px solid #e3e3e3;
    margin: 15px 0px;
  }
  .cartMainContainer {
    background-color: #e3e3e3;
    min-height: var(--MIN_HEIGHT);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .servicePolicy {
    flex: 1;
    margin: 5px;
    padding: 5px;
    background-color: #fff;
  }
  .cartNum {
    padding: 1px 5px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: var(--SHADOW);
    text-align: center;
    position: absolute;
    top: -6px;
    right: -8px;
    height: 16px;
    font-family: var(--BOLD_FONT) !important;
    min-width: 8px;
  }
  .quantityContainer {
    width: 100px;
    margin: 0px 10px;
    border-radius: 25px;
    border: 1px solid grey;
    text-align: center !important;
    user-select: none;
  }
  .addToBagContainerInList {
    position: absolute;
    width: -webkit-fill-available;
    background: rgba(0, 0, 0, 0.5);
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    bottom: 4px;
  }
  .qtyInput {
    width: 100%;
    border: none;
    outline: none;
    text-align: center;
    border-radius: 4px;
  }
  .qtyInput:focus {
    border: 0.5px solid;
  }
  .nonRestaurantInput {
    background-color: transparent;
    border-radius: 25px;
    margin: 0px 10px;
    color: white;
    padding-block: 3px;
    border: 1px solid white;
  }
  .nonRestaurantInput:focus {
    border: 1px solid white;
  }
  .catagorylist {
    margin-top: 5px;
    margin-bottom: 50px;
    padding: 12px 10px;
    background-color: #fff;
    // overflow-x: auto;
    overflow: visible;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 67%;
    margin-left: 25%;
  }
  .catListCen {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .catSectionIn {
    flex-shrink: 0;
    // min-width: 70px;
    min-width: auto;
    // height: 25px;
    height: auto;
    // display: flex;
    display: inline-block;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    font-size: 20px;
    padding: 2px 10px;
    // border-bottom: 1px solid black;
    // border-radius: 50px;
    transition: color 0.3s ease, text-decoration 0.3s ease;
    cursor: pointer;
    position: relative;
  }
  // .catSectionIn:hover::after {

  //   content: '';
  //   position: absolute;
  //   bottom: -10px; /* Adjust space between text and underline */
  //   left: 0;
  //   right: 0;
  //   height: 1px; /* Thickness of the underline */
  //   background-color: black;
  // }
  .active::after {
    // text-decoration: underline;
    // text-underline-offset: 3px;
    // color: black;
    content: '';
    position: absolute;
    bottom: -10px; /* Adjust space between text and underline */
    left: 0;
    right: 0;
    height: 1px; /* Thickness of the underline */
    background-color: black;
  }
  .microCategoryContainer {
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 4px;
    min-width: 150px;
    max-width: 200px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .micro-category-item {
    padding: 10px;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    margin: 2px 0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .micro-category-item:hover {
    background-color: #007bff;
    color: white;
  }
  .foodProductContainer {
    border-radius: 10px;
    margin: 10px;
    padding: 15px 15px 30px;
    background-color: #fff;
    box-shadow: var(--SHADOW);
    width: calc(50% - 20px);
    max-width: 420px;
    box-sizing: border-box;
    max-height: 200px;
  }
  .foodProductContainer[menu-img='no'] {
    padding: 15px;
  }
  /* Small screens (up to 768px) - 1 card per row */
  @media (max-width: 830px) {
    .foodProductContainer {
      width: calc(100% - 20px); /* Full width for single card */
    }
  }

  /* Medium screens (769px to 1200px) - 2 cards per row */
  @media (min-width: 830px) and (max-width: 1200px) {
    .foodProductContainer {
      width: calc(50% - 20px); /* 2 cards per row */
    }
  }
  .foodProductSubContainer {
    // height: calc((var(--WIDTH) / 3) + 4px);
    display: flex;
  }
  .foodProductSubContainer[menu-img='no'] {
    height: auto;
    align-items: center;
  }
  .foodProductLeftContainer {
    flex: 2;
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: space-between;
  }
  .foodProductImg {
    width: calc((var(--WIDTH) / 3));
    height: calc((var(--WIDTH) / 3));
    border-radius: 10px;
    object-fit: cover;
  }
  .foodPoductDescription {
    padding-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
    color: #000;
  }
  .discountTypeCountainer {
    margin: 0px 10px;
    border: 1px solid hsl(0, 0%, 80%);
    height: 29px;
    border-radius: 10px;
    width: 50px;
    justify-content: space-evenly;
  }
  .productDiscountField {
    .MuiInputLabel-sizeSmall[data-shrink='false'] {
      margin-top: -1px;
    }
  }
  .productDiscountFieldHeight {
    height: 15px;
  }
  .productDiscountFieldOutline {
    border-radius: 10px;
    border: 1px solid #e1e1e1;
  }

  @media screen and (max-width: 340px) {
    .addBagBtn {
      font-size: 12px;
    }
  }
}
