#WebTransactions {
  .txnContainer {
    padding: 10px;
  }
  .txnCard {
    padding: 15px;
    border-radius: 6px;
    margin: 10px 4px 0px;
    border-left-width: 6px;
    box-shadow: var(--SHADOW);
    background-color: #fff;
    border-left-style: solid;
  }
  .subHeader {
    flex-wrap: wrap;
    color: var(--MUTED_BLUE) !important;
  }
  .value {
    color: #000 !important;
  }
  .MyntsContainer {
    max-width: 800px;
    // margin: 50px auto;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .steps {
    list-style: none;
    padding: 0;
  }

  .step {
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    // background: #F0F8FF;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

  .step-icon {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 50%;
  }

  .step-content {
    font-size: 1.2rem;
  }

  // .step-content span {
  //   font-weight: bold;
  //   color: #4CAF50;
  // }
}
