#flashAd {
  text-align: center;
  position: relative;

  .img {
    width: calc(var(--WIDTH) - 70px);
    height: calc(var(--MIN_HEIGHT) - 190px);
    margin-top: 7px;
    object-fit: contain;
    padding: 5px;
  }
  .img[device='mobile'] {
    width: calc(var(--WIDTH) - 10px);
    height: calc(var(--MIN_HEIGHT) - 180px);
  }
  .closeBtn {
    top: -14px;
    right: 9px;
    position: absolute;
    color: #dbdbdb;
    font-size: 30px;
  }
  .btnContainer {
    left: 0;
    right: 0;
    // bottom: -15px;
    padding-top: 10px;
    position: absolute;
  }
  .iconContainer {
    padding: 10px;
    margin: 0px 20px;
    border-radius: 50px;
    background-color: var(--PRIMARY_BLUE);
  }
  .btn {
    font-size: 13px;
    text-wrap: nowrap;
  }
  .callBtn {
    color: #fff;
    display: block;
    font-size: 24px;
  }
  .tabConatiner {
    margin-top: 15px;
    padding: 10px 15px;
    border-radius: 7px;
  }
  .details {
    margin: 10px;
  }
  .subHeading {
    width: 90px;
    color: var(--PRIMARY_BLUE) !important;
  }
}
