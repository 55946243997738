#pageNotFound {
  min-height: var(--MIN_HEIGHT);
  background-color: var(--PRIMARY_BLUE);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .logo {
    width: 200px;
    margin-bottom: 50px;
  }

  .icon {
    color: #fff;
    font-size: 100px;
    margin-top: 10px;
  }
}
