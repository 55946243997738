#Orders {
  .container {
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: var(--SHADOW);
    background-color: #fff;
  }
  .divider {
    margin: 15px 0px;
    border-bottom: 1px solid #e6e6e6;
  }
  .section {
    padding: 15px;
    background-color: #fff;
  }
}

#TableOrder {
  .mainSection {
    min-height: calc(var(--MIN_HEIGHT) - 60px);
    display: flex;
    flex-direction: column;
  }
  .section {
    padding-top: 10px;
  }
  .btn {
    font-size: 13px;
    padding: 8px 15px;
  }
  .tableCard {
    padding: 5px 5px;
    width: 90px;
    height: 65px;
    margin: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media screen and (max-width: 390px) {
    .btn {
      font-size: 13px;
      padding: 8px 10px;
    }
  }
}

#CreateOrder {
  .foodProductContainer {
    border-radius: 10px;
    margin: 10px;
    padding: 10px 15px;
    background-color: #fff;
    box-shadow: var(--SHADOW);
  }
  .foodProductSubContainer {
    height: auto;
    align-items: center;
  }
  .foodProductLeftContainer {
    flex: 1;
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: space-between;
  }
  .addProductConatiner {
    position: absolute;
    right: 15px;
    bottom: -15px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--SHADOW);
    background-color: var(--LIGHT_COLOR);
    width: calc((var(--WIDTH) / 2) - 100px);
  }
  .productIcon {
    display: block;
    color: var(--PRIMARY_BLUE);
  }
}
