#Transactions {
  .txnContainer {
    padding: 10px;
  }
  .txnCard {
    padding: 15px;
    border-radius: 6px;
    margin: 10px 4px 0px;
    border-left-width: 6px;
    box-shadow: var(--SHADOW);
    background-color: #fff;
    border-left-style: solid;
  }
  .subHeader {
    flex-wrap: wrap;
    color: var(--MUTED_BLUE) !important;
  }
  .value {
    color: #000 !important;
  }
}
