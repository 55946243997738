#profile {
  .profileContainer {
    background-image: url(../../assets/svg/triangles.svg);
    padding: 10px 20px 30px;
    align-items: flex-start;
  }

  .profileCard {
    margin: 10px;
    padding: 15px 20px;
    border-radius: 12px;
    align-items: flex-start;
  }

  .backIcon {
    color: #fff;
    font-size: 40px;
    padding: 0px 3px;
    cursor: pointer;
  }

  .profileImgContainer {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    background-color: var(--PRIMARY_BLUE);
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .profileName {
    font-size: 35px !important;
    color: #fff !important;
  }

  .profileDetails {
    flex: 1;
    margin: 0px 0px 0px 15px;
  }

  .optionCard {
    margin: 10px;
    padding: 15px 18px;
    cursor: pointer;
  }

  .helperText {
    margin-top: 5px;
    color: var(--MUTED_BLUE) !important;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .dotIcon {
    width: 8px;
    height: 8px;
    fill: grey;
    margin: 4.5px 5px 0px;
  }
}