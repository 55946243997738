#WebBrands {
  background-color: #f9f9f9;
  .searchInput {
    width: -webkit-fill-available;
    margin-left: 7px;
  }
  .row {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .rowSB {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    box-sizing: border-box;
  }
  .rowOT {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .MenuItems {
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
    gap: 15px;
  }
  .MenuItems-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .right-side {
    display: flex;
    align-items: center;
    padding-right: 25px;
  }
  .menu-item {
    text-decoration: none;
    transition: color 0.3s ease;
  }
  .brandlayout {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .BrandName {
    margin-left: 10px;
    width: auto;
  }
  .row.cursor {
    gap: 10px;
  }
  .burger-menu {
    display: none;
  }
  .searchInput input::placeholder {
    font-size: 13px;
  }
  .searchIcon[isfocus='true'] {
    border-bottom: 2px solid var(--PRIMARY_BLUE);
  }
  .footer {
    margin-top: auto;
    padding: 8px 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #bbbbbb;
  }
  .storeName {
    padding: 7px 10px;
    text-transform: capitalize;
  }
  .followBtn {
    color: red;
    cursor: pointer;
    margin: 0px 5px;
  }
  .noStoreImg {
    width: 200px;
    height: 200px;
    margin-right: 10px;
    border-radius: 6px;
    object-fit: contain;
  }
  .storeImgContainer {
    padding: 10px 0px;
    max-height: 220px;
    display: flex;
    overflow-x: scroll;
  }
  .storeImg {
    width: 100%;
    height: 465px;
    object-fit: 'cover';
    background-color: white;
  }
  .normalStoreImg {
    width: 100%;
    height: 465px;
    object-fit: 'contain';
    background-color: white;
  }
  .subHeading {
    white-space: nowrap;
    font-size: 13px !important;
    color: var(--MUTED_BLUE) !important;
    font-family: var(--BOLD_FONT) !important;
  }

  .contentSec {
    padding: 5px 0px 10px;
    overflow-wrap: anywhere;
  }
  .aboutSections {
    margin-left: 1px;
    border-radius: 10px;
    padding: 15px;
  }
  .userPolicyOption {
    padding: 10px;
    cursor: pointer;
  }
  .userPolicyOptionCntainer {
    padding: 5px 0px;
  }
  .rowContainer {
    padding-bottom: 7px;
    align-items: flex-start;
  }
  .drawerOption {
    padding-left: 12px;
    padding-block: 10px;
    border-bottom: 1px solid rgb(222 215 215);
    gap: 10px;
  }
  .offersDisplayCard {
    margin-block: 10px;
    padding-inline: 20px;
    padding-block: 10px;
  }
  .carousel .slide {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
  }
  .carousel .slide.selected {
    opacity: 1;
  }
  .offersDisplayCard:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  .carousel .slider-wrapper {
    transition: transform 0.8s ease-in-out;
  }
  .tab-buttons {
    display: flex;
    flex-direction: row;
    gap: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 12px;
    cursor: pointer;
  }

  /* Style the tab */
  .tab {
    display: flex;
    background-color: #f1f1f1;
  }

  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    border: none;
    outline: none;
    padding: 10px 16px;
    transition: 0.3s;
    cursor: pointer;
    font-size: 17px;
  }

  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ddd;
  }

  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #ccc;
  }

  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 16px;
    border-top: none;
  }

  .tabcontent.show {
    display: block;
  }

  .tabcontent.hide {
    display: none;
  }
  //Contest Menu

  .web-store-container {
    margin: 30px 140px 270px 140px;
  }

  /* Menu buttons styling */
  .menu-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    text-align: left;
  }
  .menu-button:hover {
    transform: scale(0.95);
  }

  /* Divider */
  .divider {
    margin: 20px 0;
    height: 1px;
    background-color: #e0e0e0;
  }

  /* Content area: 3/4 of the width */
  // .content-area {
  //   flex: 3;
  //   padding: 20px;
  // }

  //Aboutus page styles

  .Heading {
    height: 40px;
    background: #f8f8f8;
    margin-top: 40px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 150px;
  }
  .aboutus-container {
    margin: 50px 140px 270px 140px;
  }
  .order-container {
    margin: 30px 140px 270px 140px;
  }
  .coupon-container {
    margin: 30px 140px 150px 140px;
  }
  .reward-container {
    padding-top: 10px;
    margin-bottom: 95px;
  }
  .contactus-container {
    margin: 30px 140px 150px 140px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* Sidebar menu: 1/4 of the width */
  .policy-container {
    margin: 30px 140px 150px 140px;
    display: flex;
  }
  .sidebar-menu {
    flex: 1;
    max-width: 25%;
    padding: 20px;
  }
  // .content-area {
  //   flex: 3;
  //   padding: 20px;
  // }

  .custom-product-section-container {
    width: full;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-section {
    max-width: 1300px;
    height: full;
    margin: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .ps-first-row {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    .first-row-img {
      width: 100%;
      display: block;
      object-fit: cover;
      height: 600px;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
      color: #fff;
      text-align: center;
      .view-button {
        margin-top: 10px;
        padding: 8px 16px;
        background: transparent;
        border: 2px solid #fff;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
  }

  .second-row-img-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    .second-row-img {
      width: 100%;
      display: block;
      object-fit: cover;
      height: 295px;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
      color: #fff;
      text-align: center;
      .view-button {
        margin-top: 10px;
        padding: 8px 16px;
        background: transparent;
        border: 2px solid #fff;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
  }

  .ps-second-row {
    display: flex;
    gap: 20px;
  }

  .second-row-img {
    height: 295px;
    width: 295px;
    background-color: #e9e8e8;
    border-radius: 10px;
  }

  .filter-name {
    margin-bottom: 5px;
  }

  .grid-layout {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    justify-content: center;
    justify-self: center;
    padding: 20px;

    .grid-item {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }

    .grid-img {
      width: 100%;
      height: 300px;
      display: block;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .view-button {
      background: transparent;
      border: 2px solid white;
      color: white;
      padding: 6px 12px;
      cursor: pointer;
      transition: 0.3s ease;
      border-radius: none !important;
      &:hover {
        background: white;
        color: black;
      }
    }
  }

  .non-image-product-section {
    max-width: 1300px;
    height: full;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .custom-product-section-container-no-image {
    width: full;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .product-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    overflow-x: auto;
    padding: 10px;
  }

  .filter-product-image {
    height: 300px;
    width: 300px;
    border-radius: 5px;
  }

  .shopByBrands {
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .brandRow {
      gap: 15px;
      overflow-x: auto;
      align-items: start;
    }

    .brand {
      cursor: pointer;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .logo {
        border-radius: 5px;
        width: 90px;
        height: 90px;
        margin-bottom: 5px;
      }

      .name {
        font-weight: bold;
      }
    }
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .icon-image {
    width: 600px;
    height: 400px;
    object-fit: cover;
  }
  /* Footer Styles */
  .Storefooter {
    background-color: #f8f8f8;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    margin-bottom: -65px;
  }

  .footer-content {
    margin: 5% 15%;
  }

  .footer-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin: 0;
  }

  .menu-item {
    cursor: pointer;
    font-size: 14px;
  }

  .menu-item:hover {
    color: #007bff; /* Example hover effect */
  }

  //   @media (min-width: 769px) {
  //   // .storelayout {
  //   //   padding: 40px 20px;
  //   // }
  //   .normalStoreImg {
  //     width: 100%;
  //     height: 450px; /* Larger height for desktop */
  //   }
  //   .MenuItems {
  //     display: flex;
  //     justify-content: space-between;
  //     padding-right: 15px;
  //   }
  //   .StoreName {
  //     width: auto;
  //     font-size: 18px; /* Larger font for desktop */
  //   }
  // }

  //   /* Ultra-small devices (below 360px) */
  //   @media (max-width: 360px) {
  //     .right-side {
  //       gap: 0px;
  //     }
  //     .burger-menu {
  //       display: block;
  //     }
  //     .MenuItems {
  //       display: none;
  //     }
  //     .MenuItems-footer {
  //       display: flex;
  //       flex-wrap: wrap;
  //       align-items: center;
  //       justify-content: center;
  //     }
  //     .storelayout {
  //       padding-left: 5px;
  //       padding-right: 5px;
  //     }
  //     .normalStoreImg {
  //       width: 100%;
  //       height: 200px; /* Further reduce height for very small screens */
  //     }
  //     .StoreName {
  //       width: 140px;
  //       font-size: 14px; /* Adjust text size */
  //     }
  //     .row.cursor {
  //       gap: 0px;
  //     }
  //     .menu-item {
  //       font-size: 12px; /* Smaller font for footer links */
  //       padding: 5px 10px;
  //     }
  //     .policy-container {
  //       display: flex;
  //       flex-direction: column;
  //     }
  //     .sidebar-menu {
  //       max-width: 100%;
  //       padding: 10px;
  //     }
  //     .content-area {
  //       max-width: 100%;
  //       padding: 10px;
  //     }
  //   }

  //   /* Mobile screens (up to 480px) */
  //   @media (max-width: 480px) {
  //     .storelayout {
  //       padding-left: 10px;
  //       padding-right: 10px;
  //     }
  //     .burger-menu {
  //       display: block; /* Show burger menu on small screens */
  //     }
  //     .MenuItems {
  //       display: none; /* Hide on mobile */
  //     }
  //     .MenuItems-footer {
  //       display: flex;
  //       flex-wrap: wrap;
  //       align-items: center;
  //       justify-content: center;
  //     }
  //     .normalStoreImg {
  //       width: 100%;
  //       height: 250px; /* Smaller height for mobile */
  //     }
  //     .StoreName {
  //       width: 160px;
  //     }
  //     .row.cursor {
  //       gap: 0px; /* No gap on small screens */
  //     }
  //     .footer-menu {
  //       display: flex;
  //       flex-wrap: wrap;
  //     }
  //     .footer-content {
  //       flex-direction: column;
  //     }

  //     .menu-item {
  //       padding: 5px 10px;
  //     }
  //     .policy-container {
  //       display: flex;
  //       flex-direction: column;
  //     }
  //     .sidebar-menu {
  //       max-width: 100%;
  //       padding: 10px;
  //     }
  //     .content-area {
  //       max-width: 100%;
  //       padding: 10px;
  //     }
  //   }

  //   /* Medium screens (up to 600px) */
  //   @media (max-width: 600px) {
  //     .storelayout {
  //       padding-left: 10px;
  //       padding-right: 10px;
  //     }
  //     .burger-menu {
  //       display: block;
  //     }
  //     .MenuItems {
  //       display: none;
  //     }
  //     .MenuItems-footer {
  //       display: flex;
  //       flex-wrap: wrap;
  //       align-items: center;
  //       justify-content: center;
  //     }
  //     .normalStoreImg {
  //       width: 100%;
  //       height: 300px; /* Adjust height for medium screens */
  //     }
  //     .StoreName {
  //       width: 170px;
  //     }
  //     .row.cursor {
  //       gap: 5px; /* Slightly larger gap */
  //     }
  //     .policy-container {
  //       display: flex;
  //       flex-direction: column;
  //     }
  //     .sidebar-menu {
  //       max-width: 100%;
  //       padding: 10px;
  //     }
  //     .content-area {
  //       max-width: 100%;
  //       padding: 10px;
  //     }
  //   }
  //   @media (max-width: 768px) {
  //     .storelayout {
  //       padding-left: 15px;
  //       padding-right: 15px;
  //     }
  //     .burger-menu {
  //       display: block;
  //     }
  //     .MenuItems {
  //       display: none;
  //     }
  //     .MenuItems-footer {
  //       display: flex;
  //       flex-wrap: wrap;
  //       align-items: center;
  //       justify-content: center;
  //     }
  //     .normalStoreImg {
  //       width: 100%;
  //       height: 350px; /* Adjust height for tablets */
  //     }
  //     .StoreName {
  //       width: 180px;
  //     }
  //     .row.cursor {
  //       gap: 10px;
  //     }
  //     .footer-content {
  //       flex-direction: column;
  //     }
  //     .footer-menu {
  //       display: flex;
  //       flex-wrap: wrap;
  //     }
  //     .policy-container {
  //       display: flex;
  //       flex-direction: column;
  //     }
  //     .sidebar-menu {
  //       max-width: 100%;
  //       padding: 10px;
  //     }
  //     .content-area {
  //       max-width: 100%;
  //       padding: 10px;
  //     }
  //   }
  // }

  // @media (min-width: 769px) {
  //   .storelayout {
  //     padding-top: 20px;
  //     padding-bottom: 20px;
  //   }
  //   .normalStoreImg {
  //     width: 100%;
  //     height: 450px; /* Larger height for desktop */
  //   }
  //   .MenuItems {
  //     display: flex;
  //     justify-content: space-between;
  //     padding-right: 15px;
  //   }
  //   .StoreName {
  //     width: auto;
  //     font-size: 18px; /* Larger font for desktop */
  //   }
  // }
}
