#appContainer {
  margin: auto;
  min-height: var(--MIN_HEIGHT);
  min-width: 320px;
  max-width: 425px;
  background-color: #fff;
}

#webAppContainer {
  height: calc(100vh - 0px); /* Adjust height to account for Topbar */
  background-color: #fff;
  overflow-y: auto; /* Enable vertical scrolling for content if needed */
  box-sizing: border-box;
}
