#auth {
  min-height: var(--MIN_HEIGHT);
  background-color: var(--PRIMARY_BLUE);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;

  .logo {
    width: 200px;
    margin-bottom: 25px;
  }

  .container {
    padding: 20px;
    margin-top: 25px;
    border-radius: 10px;
    background-color: #fff;
    width: calc(100% - 40px);
  }
  .passwordContainer {
    max-width: 450px;
    margin: 50px auto;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
