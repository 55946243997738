#Refer {
  padding: 20px;

  .codeContainer {
    width: fit-content;
    padding: 10px 20px;
    border-radius: 10px;
    margin: 10px auto 20px;
    background-color: #e6e6e6;
  }
}
