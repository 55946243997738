#webAuth {
  .container {
    display: flex;
    flex-direction: row;
  }

  .banner {
    width: 70%;
    height: 100vh;
    background-color: #1a476e;
    display: flex;
    align-items: start;
  }

  .signin-container {
    display: flex;
    // flex-direction: column;
    background-color: white;
    align-items: center;
    justify-content: center;
    width: 30%;
  }

  .signin {
    margin: 30px;
    width: 450px;
  }

  @media (max-width: 1300px) {
    .banner {
      width: 60%;
    }

    .signin-container {
      width: 40%;
    }

    .signin {
      width: 550px;
    }
  }
}

#registration {
  .custom-button {
    background-color: #ffff;
    color: black;
    // border: 1px solid #ccc;
    transition: background-color 0.3s ease; /* Smooth transition */
  }

  .custom-button:hover {
    background-color: #f1f2f4; /* Change to red on hover */
  }
}
