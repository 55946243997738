#DownloadApp {
  min-height: var(--MIN_HEIGHT);
  background-color: var(--PRIMARY_BLUE);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .logo {
    width: 200px;
    margin-bottom: 40px;
  }

  .infoText {
    color: #fff !important;
    padding: 0px 15px 7px;
  }

  .store {
    width: 150px;
    flex-wrap: wrap;
    margin: 20px 5px 0px;
  }
}