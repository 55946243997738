#SignUp {
    min-height: var(--MIN_HEIGHT);
    background-color: #1a476e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
  
    // for mobile view
    margin: auto;
    min-width: 320px;
    max-width: 425px;
    box-sizing: border-box;
  
    .logo {
      width: 80px;
      margin-bottom: 25px;
    }
  
    .container {
      padding: 20px;
      margin-top: 25px;
      border-radius: 10px;
      background-color: #fff;
      width: calc(100% - 40px);
    }
  }
  
  #StoreRegistration {
    .detailsCard {
      padding: 14px;
      margin-bottom: 20px;
    }
  }
  