#Location {
  display: flex;
  min-height: var(--MIN_HEIGHT);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: var(--PRIMARY_BLUE);

  .logo {
    width: 200px;
    margin-bottom: 25px;
  }

  .container {
    padding: 20px;
    margin-top: 25px;
    border-radius: 10px;
    background-color: #fff;
    width: calc(100% - 40px);
    box-shadow: var(--SHADOW);
  }
}
