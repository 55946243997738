#AuthLanding {
  .topBar {
    padding: 13px 20px;
    border-radius: 0px;
    position: fixed;
    width: -webkit-fill-available;
    box-sizing: border-box;
  }

  .logo {
    width: 90px;
    object-fit: contain;
    cursor: pointer;
  }

  .brandsSection {
    width: fit-content;
    margin: auto;
  }
}
