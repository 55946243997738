#Stores {
  .noStore {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: calc(var(--WIDTH) - 50px);
  }
  .searchInput {
    width: -webkit-fill-available;
    margin-left: 7px;
  }
  .searchInput input::placeholder {
    font-size: 13px;
  }
  .searchIcon[isfocus='true'] {
    border-bottom: 2px solid var(--PRIMARY_BLUE);
  }
  .footer {
    margin-top: auto;
    padding: 8px 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--ICE_BLUE);
  }
  .storeName {
    padding: 7px 10px;
    text-transform: capitalize;
  }
  .followBtn {
    color: red;
    cursor: pointer;
    margin: 0px 5px;
  }
  .noStoreImg {
    width: 200px;
    height: 200px;
    margin-right: 10px;
    border-radius: 6px;
    object-fit: contain;
  }
  .storeImgContainer {
    padding: 10px 0px;
    max-height: 220px;
    display: flex;
    overflow-x: scroll;
  }
  .storeImg {
    width: var(--WIDTH);
    height: calc(var(--WIDTH) / 2);
    border-radius: 6px;
    object-fit: contain;
    background-color: white;
  }
  .normalStoreImg {
    width: var(--WIDTH);
    height: var(--WIDTH);
    object-fit: fill;
    background-color: white;
  }
  .subHeading {
    white-space: nowrap;
    font-size: 13px !important;
    color: var(--MUTED_BLUE) !important;
    font-family: var(--BOLD_FONT) !important;
  }
  .contentSec {
    // padding: 5px 0px 10px;
    // overflow-wrap: anywhere;
  }
  .aboutSections {
    // margin-left: 1px;
    // border-radius: 10px;
    // padding: 15px;
  }
  .userPolicyOption {
    padding: 10px;
    cursor: pointer;
  }
  .userPolicyOptionCntainer {
    padding: 5px 0px;
  }
  .rowContainer {
    padding-bottom: 7px;
    align-items: flex-start;
  }
  .drawerOption {
    padding-left: 12px;
    padding-block: 10px;
    border-bottom: 1px solid rgb(222 215 215);
    gap: 10px;
  }
  .offersDisplayCard {
    margin-block: 10px;
    margin-inline: 15px;
    padding-inline: 20px;
    padding-block: 10px;
    border-radius: 15px;
  }
  .InnerlogoName {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
    //  justify-content: center;
    //  height: 40px;
  }
  .MenuItems-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    gap: 3px;
  }

  /* Footer Styles */
  .Storefooter {
    background-color: #f8f8f8;
    padding: 20px 0;
    width: 100%;
    margin-bottom: -65px;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0px;
  }

  .footer-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin: 0;
  }

  .menu-item {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
  }

  .menu-item:hover {
    color: #007bff; /* Example hover effect */
  }

  .whatsAppIcon {
    position: sticky;
    bottom: 0px;
    text-align: right;
    width: fit-content;
    margin-left: auto;
    z-index: 1;
    padding: 10px 10px;
  }

  .custom-product-section-container {
    width: full;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }

  .product-section {
    max-width: 1300px;
    height: full;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .ps-first-row {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    .first-row-img {
      width: 100%;
      display: block;
      object-fit: cover;
      height: 400px;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
      color: #fff;
      text-align: center;
      .view-button {
        margin-top: 10px;
        padding: 8px 16px;
        background: transparent;
        border: 2px solid #fff;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
  }

  .second-row-img-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    .second-row-img {
      width: 100%;
      display: block;
      object-fit: cover;
      height: 295px;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
      color: #fff;
      text-align: center;
      .view-button {
        margin-top: 10px;
        padding: 8px 16px;
        background: transparent;
        border: 2px solid #fff;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
  }

  .ps-second-row {
    display: flex;
    gap: 10px;
  }

  .second-row-img {
    height: 295px;
    width: 295px;
    background-color: #e9e8e8;
    border-radius: 10px;
  }

  .filter-name {
    margin-bottom: 5px;
  }

  .grid-layout {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    justify-content: center;
    justify-self: center;
    width: 100%;

    .grid-item {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      width: 100%;
    }

    .grid-img {
      width: 100%;
      height: 200px;
      display: block;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .view-button {
      background: transparent;
      border: 2px solid white;
      color: white;
      padding: 6px 12px;
      cursor: pointer;
      transition: 0.3s ease;
      border-radius: none !important;
      height: 30px;
      &:hover {
        background: white;
        color: black;
      }
    }
  }

  .non-image-product-section {
    height: full;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .custom-product-section-container-no-image {
    width: full;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .product-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    overflow-x: auto;
    max-width: 350px;
    padding: 10px;
  }

  .filter-product-image {
    background-color: #e9e8e8;
    height: 300px;
    width: 300px;
  }

  .shopByBrands {
    padding: 10px 20px 10px 20px;

    .brandRow {
      gap: 15px;
      overflow-x: auto;
    }

    .brand {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .logo {
        border-radius: 5px;
        width: 60px;
        height: 60px;
        margin-bottom: 5px;
      }

      .name {
        font-weight: bold;
      }
    }
  }

  /* Ultra-small devices (below 360px) */
  @media (max-width: 360px) {
    .storelayout {
      padding-left: 5px;
      padding-right: 5px;
    }
    .normalStoreImg {
      width: 100%;
      height: 200px; /* Further reduce height for very small screens */
    }
    .StoreName {
      width: 150px;
      font-size: 14px; /* Adjust text size */
    }
    .row.cursor {
      gap: 0px;
    }
    .menu-item {
      font-size: 12px; /* Smaller font for footer links */
      padding: 5px 10px;
    }
  }

  /* Mobile screens (up to 480px) */
  @media (max-width: 480px) {
    .storelayout {
      padding-left: 10px;
      padding-right: 10px;
    }
    .burger-menu {
      display: block; /* Show burger menu on small screens */
    }
    .MenuItems {
      display: none; /* Hide on mobile */
    }
    .normalStoreImg {
      width: 100%;
      height: 250px; /* Smaller height for mobile */
    }
    .StoreName {
      width: 160px;
    }
    .row.cursor {
      gap: 0px; /* No gap on small screens */
    }
    .footer-menu {
      display: flex;
      flex-wrap: wrap;
    }
    .footer-content {
      flex-direction: column;
    }

    .menu-item {
      padding: 5px 10px;
    }
  }
}

#visitedStores {
  .visitedStoreSection {
    gap: 10px;
    padding: 25px;
    overflow-x: scroll;
  }
  .visitedStoreContainer {
    width: 180px;
    padding: 2px;
    border-radius: 6px;
    box-shadow: var(--SHADOW);
    background-color: #fff;
  }
  .visitedStoreImg {
    width: 180px;
    height: 180px;
    border-radius: 4px;
  }
  .footer {
    margin: auto -2px -2px;
    padding: 8px 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--ICE_BLUE);
  }
}
