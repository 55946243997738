#policy {
  .policyCard {
    margin: 10px;
    padding: 15px 18px;
    cursor: pointer;
  }

  .helperText {
    margin-top: 5px;
    color: var(--MUTED_BLUE) !important;
  }

  .icon {
    width: 30px;
    height: 30px;
    }
}