#WebVisitedStores {
  position: fixed;
  top: 30;
  right: 0;
  width: 300px;
  height: 81vh;
  overflow-y: auto;

  .visitedList {
    padding: 20px;

    .visitedStoreSection {
      align-items: center;
      margin-bottom: 15px;
    }

    .visitedStoreImg {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
      border: 2px solid #ddd;
      margin-bottom: 1px;
      margin-top: 10px;
    }

    .footer {
      width: 100%;
    }
  }
}
