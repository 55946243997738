#Products {
  padding-bottom: 10px;

  .itemTypeBtn {
    padding: 5px 0px;
    font-size: 18px !important;
  }
  .itemTypeBtn[active='false'] {
    border: none;
  }
  .itemTypeBtn[active='true'] {
    border-bottom: 1px solid green;
  }
  .productsMainContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 8px;
  }
  .catSection {
    margin-top: 5px;
    padding: 12px 20px;
    background-color: #f7f4f4;
  }
  .catSectionNew {
    margin-top: 5px;
    padding: 12px 10px;
    background-color: #f7f4f4;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    gap: 5px;
  }
  .catSectionIn {
    flex-shrink: 0;
    min-width: 70px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2px 10px;
    border: 1px solid rgb(184, 178, 202);
    border-radius: 50px; /* Oval shape */
    transition: border-color 0.3s ease, background-color 0.3s ease;
  }

  .active {
    border-color: #029702; /* Highlight color */
  }
  .productContainer {
    margin: 7px;
    margin-top: 5px;
    width: calc((var(--WIDTH) / 2) - 22px);
  }
  .productImg {
    width: 100%;
    height: 234px;
    border-radius: 7px;
    object-fit: contain;
    background-color: #fff;
    box-shadow: var(--SHADOW);
  }
  .poductDescription {
    padding-top: 4px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    text-overflow: ellipsis;
  }
  .poductOffersDescription {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    text-overflow: ellipsis;
  }
  .productColor {
    padding: 30px;
    margin: 10px 4px;
    border-radius: 60%;
    box-shadow: 0px 0px 3px 0px gray;
  }
  .addProductConatiner {
    position: absolute;
    right: 15px;
    bottom: -15px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--SHADOW);
    background-color: var(--LIGHT_COLOR);
    width: calc((var(--WIDTH) / 3) - 50px);
  }
  .productIcon {
    display: block;
    color: var(--PRIMARY_BLUE);
  }
  .changeProductConatiner {
    width: 80px;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
  }
  .viewCart {
    margin: 10px;
    padding: 20px;
    border-radius: 15px;
    background-color: var(--PRIMARY_BLUE);
  }

  .productCartContainer {
    padding: 15px;
    background-color: #fff;
  }
  .placeOrderBtn {
    width: 150px;
    padding: 10px 15px;
    border-radius: 10px !important;
    margin-top: -5px;
  }
  .productErr {
    font-size: 10px !important;
    margin: 2px 0px 0px;
    color: var(--ERROR) !important;
  }
  .divider {
    border-bottom: 1px solid #e3e3e3;
    margin: 15px 0px;
  }
  .cartMainContainer {
    background-color: #e3e3e3;
    min-height: var(--MIN_HEIGHT);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .servicePolicy {
    flex: 1;
    margin: 5px;
    padding: 5px;
    background-color: #fff;
  }
  .cartBtnContainer {
    position: fixed;
    bottom: 0;
    width: var(--WIDTH);
    margin: auto;
    z-index: 10;
    background-color: #fff;
    padding: 15px 10px;
    box-shadow: 0px 0px 12px -7px black;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-sizing: border-box;
  }
  .cartBtn {
    padding: 10px;
    border-radius: 15px;
    margin: auto;
    background-color: var(--PRIMARY_BLUE);
  }
  .cartNum {
    margin-left: 6px;
    padding: 0px 6px;
    border-radius: 10px;
  }
  .webCartNum {
    padding: 1px 5px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: var(--SHADOW);
    text-align: center;
    position: absolute;
    top: -6px;
    right: -8px;
    height: 16px;
    font-family: var(--BOLD_FONT) !important;
    min-width: 8px;
  }
  .quantityContainer {
    width: 100px;
    margin: 0px 10px;
    border-radius: 25px;
    border: 1px solid grey;
    text-align: center !important;
    user-select: none;
  }
  .addToBagContainerInList {
    position: absolute;
    width: -webkit-fill-available;
    background: rgba(0, 0, 0, 0.5);
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    bottom: 4px;
  }

  .foodProductContainer {
    border-radius: 10px;
    margin: 10px;
    padding: 15px 15px 30px;
    background-color: #fff;
    box-shadow: var(--SHADOW);
  }
  .foodProductContainer[menu-img='no'] {
    padding: 15px;
  }
  .foodProductSubContainer {
    // height: calc((var(--WIDTH) / 3) + 4px);
    display: flex;
  }
  .foodProductSubContainer[menu-img='no'] {
    height: auto;
    align-items: center;
  }
  .foodProductLeftContainer {
    flex: 1;
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: space-between;
  }
  .foodProductImg {
    width: calc((var(--WIDTH) / 3));
    height: calc((var(--WIDTH) / 3));
    border-radius: 10px;
    object-fit: cover;
  }
  .foodPoductDescription {
    padding-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    text-overflow: ellipsis;
    line-break: anywhere;
  }
  .discountTypeCountainer {
    margin: 0px 10px;
    border: 1px solid hsl(0, 0%, 80%);
    height: 29px;
    border-radius: 10px;
    width: 50px;
    justify-content: space-evenly;
  }
  .productDiscountField {
    .MuiInputLabel-sizeSmall[data-shrink='false'] {
      margin-top: -1px;
    }
  }
  .productDiscountFieldHeight {
    height: 15px;
  }
  .productDiscountFieldOutline {
    border-radius: 10px;
    border: 1px solid #e1e1e1;
  }
  .qtyInput {
    width: 100%;
    border: none;
    outline: none;
    text-align: center;
    border-radius: 4px;
  }
  .qtyInput:focus {
    border: 0.5px solid;
  }
  .nonRestaurantInput {
    background-color: transparent;
    border-radius: 25px;
    margin: 0px 10px;
    color: white;
    padding-block: 3px;
    border: 1px solid white;
  }
  .nonRestaurantInput:focus {
    border: 1px solid white;
  }
  .detailsInput {
    padding: 5px 0px;
    margin: 0px 10px;
    border-radius: 25px;
    border: 0.5px solid;
  }
  .chipBtn {
    padding: 5px 10px;
    border: 1px solid var(--PRIMARY_BLUE);
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
  }
  .chipBtn[isselected='true'] {
    background-color: var(--DULL_BLUE);
  }
  .chipBtn[isactive='false'] {
    border: 1px dashed gray;
    color: grey !important;
  }
  .colorChip {
    box-shadow: 0px 0px 3px 0px gray;
    width: 13px;
    height: 23px;
    border: 2px solid #fff;
    outline: 0;
  }
  .colorChip[isselected='true'] {
    outline: 2px solid var(--PRIMARY_BLUE);
  }
  .colorChip[isactive='false'] {
    outline: 2px dashed grey;
    border: 2px solid #fff;
    opacity: 0.5;
  }

  @media screen and (max-width: 340px) {
    .addBagBtn {
      font-size: 12px;
    }
  }
}
