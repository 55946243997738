.suggested-list {
    width: 80%;
    margin-left: 20px;
    margin-top: 13px;
    font-family: Arial, sans-serif;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .header h4 {
    font-size: 16px;
    font-weight: bold;
  }
  
  .see-all {
    color: #0095f6;
    font-size: 14px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .suggestion-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

  }
  
  .profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    border: 2px solid #ddd; 
    
  }
  
  .user-info {
    flex: 1;
  }
  
  .username {
    font-size: 14px;
    font-weight: bold;
  }
  
  .followers {
    font-size: 12px;
    color: #8e8e8e;
  }
  
  .follow-button {
    color: white;
    background-color: #0095f6;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .follow-button:hover {
    background-color: #007bb5;
  }
  