#Notifications {
  .notificationContainer {
    box-shadow: var(--SHADOW);
    background-color: #ffffff;
    margin: 10px 10px 0px;
    border-radius: 10px;
    padding: 10px;
    align-items: flex-start;
  }
  .notificationContainer[read='true'] {
    background-color: #eeeeee;
  }
}

#WebNotifications {
  .notificationContainer {
    box-shadow: var(--SHADOW);
    background-color: #ffffff;
    margin: 10px 10px 0px;
    border-radius: 10px;
    padding: 10px;
  }
  .notificationContainer[read='true'] {
    background-color: #eeeeee;
  }
}
