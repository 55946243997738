#Brands {
  .searchInput {
    width: -webkit-fill-available;
    margin-left: 7px;
  }
  .searchInput input::placeholder {
    font-size: 13px;
  }
  .searchIcon[isfocus='true'] {
    border-bottom: 2px solid var(--PRIMARY_BLUE);
  }
  .footer {
    padding: 8px 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--ICE_BLUE);
  }
  .banner {
    object-fit: cover;
    width: var(--WIDTH);
    height: calc(var(--WIDTH) / 2);
  }
  .noBanner {
    background-image: linear-gradient(160deg, #2f80ed, #56ccf2);
  }
  .noBannerText {
    color: #fff !important;
    text-align: center;
    padding-top: calc(var(--WIDTH) / 4 - 40px);
  }
  .logoContainer {
    width: 132.5px;
    height: 132.5px;
    border-radius: 135px;
    background-color: #fff;
    position: absolute;
    bottom: -40px;
    left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 125px;
    height: 125px;
    border-radius: 10000px;
    object-fit: contain;
  }
  .brandName {
    margin-top: 55px;
  }
  .followBtn {
    color: red;
    cursor: pointer;
    margin-right: 5px;
  }
  .icon {
    margin: 0px 5px;
    font-size: 17px;
  }
  .subHeading {
    padding: 0px 4px 3px 0px;
    font-family: var(--BOLD_FONT) !important;
  }
  .rowContainer {
    padding-bottom: 7px;
    align-items: flex-start;
  }
  .plusIcon {
    width: 35px;
    padding: 5px;
    border-radius: 50;
    margin: 10px 0px;
    margin: 0px 20px;
  }
  .aboutSections {
    margin-left: 1px;
    border-radius: 10px;
    padding: 15px;
  }
  .userPolicyOptionCntainer {
    padding: 5px 0px;
  }
  .userPolicyOption {
    padding: 10px;
    cursor: pointer;
  }
}
