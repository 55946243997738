#WebOrders {
  .container {
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: none !important;
    display: block;
  }
  .tab-buttons {
    display: flex;
    flex-direction: row;
    gap: 0;
  }
  .fullscreen-table-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .fullscreen-table tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .table-full-width {
    width: 100%;
    min-width: 300px; /* Ensure the minimum width if needed */
  }
  thead {
    background-color: #f9f9fa;
  }
  td {
    padding: 15px;
  }
  .ordertype-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .divider {
    margin: 15px 0px;
    border-bottom: 1px solid #e6e6e6;
  }
  .section {
    background-color: #fff;
  }
  .rowOT {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }
}

#WebOrderDetails {
  display: flex;
  flex-direction: column;

  .orderDetailsLayout {
    padding: 10px 15px;
  }

  .cardStyle {
    border: 2px solid #eeeeee;
    border-radius: 10px;
    padding: 12px;
    box-shadow: #00000017 0px 0px 5px 0px;
    margin-bottom: 10px;
  }
  .closeContainer {
    display: flex;
    justify-content: flex-end;
    padding: 7px;
    background-color: #f9f9fa;
  }
  .iconHeading .Heading {
    color: #3076b5 !important;
    font-size: 14px;
    padding: 2px 10px;
    border-left: 3px solid #3076b5;
    margin-left: -13px;
  }
  .iconHeading {
    display: flex;
    align-items: center;
  }
  .iconHeading {
    display: flex;
    gap: 5px;
    padding-bottom: 10px;
  }
  .page-header {
    padding: 5px 0 0 5px;
    display: flex;
    justify-content: space-between;
  }
  .detailed-view-btn {
    width: 150px;
    padding: 10px 10px 10px 10px;
    background-color: #000000;
    border-radius: 10px;
    gap: 5px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    height: 20px;
    cursor: pointer;
  }
}

#TableOrder {
  .mainSection {
    min-height: calc(var(--MIN_HEIGHT) - 60px);
    display: flex;
    flex-direction: column;
  }
  .section {
    padding-top: 10px;
  }
  .btn {
    font-size: 13px;
    padding: 8px 15px;
  }
  .tableCard {
    padding: 5px 5px;
    width: 90px;
    height: 65px;
    margin: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media screen and (max-width: 390px) {
    .btn {
      font-size: 13px;
      padding: 8px 10px;
    }
  }
}

#CreateOrder {
  .foodProductContainer {
    border-radius: 10px;
    margin: 10px;
    padding: 10px 15px;
    background-color: #fff;
    box-shadow: var(--SHADOW);
  }
  .foodProductSubContainer {
    height: auto;
    align-items: center;
  }
  .foodProductLeftContainer {
    flex: 1;
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: space-between;
  }
  .addProductConatiner {
    position: absolute;
    right: 15px;
    bottom: -15px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: var(--SHADOW);
    background-color: var(--LIGHT_COLOR);
    width: calc((var(--WIDTH) / 2) - 100px);
  }
  .productIcon {
    display: block;
    color: var(--PRIMARY_BLUE);
  }
}
