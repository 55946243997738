#PublishAd {
  // List
  .titleIcon {
    color: #ffffff;
    padding-top: 7px;
  }
  .listImg {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-top: 5px;
  }

  .checkbox {
    width: 14px;
    height: 14px;
    margin: 0px 2px;
    border: 1px solid var(--PRIMARY_BLUE);
    border-radius: 3px;
    margin: 2px 0px;
  }
  .uploadOuterContainer {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .uploadContainer {
    padding: 15px;
    border: 0.5px dashed var(--PRIMARY_BLUE);
    border-radius: 8px;
    height: calc(var(--WIDTH) * 0.6);
    width: calc(var(--WIDTH) * 0.6);
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .uploadedAdImg {
    height: calc(var(--WIDTH) * 0.6);
    width: calc(var(--WIDTH) * 0.6);
    object-fit: contain;
  }
  .changeImgContainer {
    opacity: 0.8;
    padding: 20px 0px;
    margin-top: -57px;
    background-color: var(--LIGHT_COLOR);
  }
  .errorMsg {
    margin: -4px 4px 2px;
  }
  .previewImg {
    border-radius: 8px;
    width: calc(var(--WIDTH) - 30px);
    height: calc(var(--WIDTH) - 30px);
    object-fit: contain;
  }
  .subHeading {
    color: var(--MUTED_BLUE) !important;
  }
  .content {
    padding: 0px 10px;
  }
  .adLocation {
    padding-top: 8px;
    color: var(--DARK_COLOR) !important;
  }
  .imgPickerContainer {
    padding: 10px 15px;
  }
  .stepBtn {
    padding: 5px;
    min-width: fit-content;
    height: unset !important;
    border-radius: 50px !important;
  }

  // Ad Tip
  .adTipContainer {
    margin: 20px 0px 8px;
  }
  .adTipHeading {
    color: var(--PRIMARY_BLUE) !important;
    margin-bottom: 4px;
  }
  .adTipLabels {
    color: var(--DARK_BLUE) !important;
    font-size: 13px !important;
    margin-bottom: 4px;
  }

  // Details
  .statusIndicator {
    position: absolute;
    width: 0;
    height: 0;
    margin: 2px;
    left: 0px;
    top: 0px;
    border-top-left-radius: 7px;
    background-color: transparent;
    border: 20px solid transparent;
  }

  .detailsContainer {
    margin: 5px 10px;
  }
  .details {
    margin: 10px 0px;
  }
  .drawImg {
    margin-top: 4px;
    border-radius: 8px;
    object-fit: contain;
    width: calc(var(--WIDTH) - 28px);
    height: calc(var(--WIDTH) - 28px);
  }
  .statusContainer {
    margin-top: 20px;
    padding: 0px 20px;
  }
  .tabConatiner {
    margin-top: 15px;
    padding: 10px 15px;
    border-radius: 7px;
  }
  .interestedDetailsContainer {
    padding: 15px;
    margin: 7px 3px 0px;
    border-radius: 6px;
  }
}
