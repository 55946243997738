#BrandsDetails {
  .noBrand {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: calc(var(--WIDTH) - 50px);
  }
  .searchInput {
    width: -webkit-fill-available;
    margin-left: 7px;
  }
  .searchInput input::placeholder {
    font-size: 13px;
  }
  .searchIcon[isfocus='true'] {
    border-bottom: 2px solid var(--PRIMARY_BLUE);
  }
  .footer {
    margin-top: auto;
    padding: 8px 10px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--ICE_BLUE);
  }
  .brandName {
    padding: 7px 10px;
    text-transform: capitalize;
  }
  .followBtn {
    color: red;
    cursor: pointer;
    margin: 0px 5px;
  }
  .noBrandImg {
    width: 200px;
    height: 200px;
    margin-right: 10px;
    border-radius: 6px;
    object-fit: contain;
  }
  .brandImgContainer {
    padding: 10px 0px;
    max-height: 220px;
    display: flex;
    overflow-x: scroll;
  }
  .brandImg {
    width: var(--WIDTH);
    height: calc(var(--WIDTH) / 2);
    border-radius: 6px;
    object-fit: contain;
    background-color: white;
  }
  .normalBrandImg {
    width: var(--WIDTH);
    height: var(--WIDTH);
    object-fit: fill;
    background-color: white;
  }
  .subHeading {
    white-space: nowrap;
    font-size: 13px !important;
    color: var(--MUTED_BLUE) !important;
    font-family: var(--BOLD_FONT) !important;
  }
  .contentSec {
    padding: 5px 0px 10px;
    overflow-wrap: anywhere;
  }
  .aboutSections {
    margin-left: 1px;
    border-radius: 10px;
    padding: 15px;
  }
  .userPolicyOption {
    padding: 10px;
    cursor: pointer;
  }
  .userPolicyOptionCntainer {
    padding: 5px 0px;
  }
  .rowContainer {
    padding-bottom: 7px;
    align-items: flex-start;
  }
  .drawerOption {
    padding-left: 12px;
    padding-block: 10px;
    border-bottom: 1px solid rgb(222 215 215);
    gap: 10px;
  }
  .offersDisplayCard {
    margin-block: 10px;
    margin-inline: 15px;
    padding-inline: 20px;
    padding-block: 10px;
    border-radius: 15px;
  }
  .InnerlogoName {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
    //  justify-content: center;
    //  height: 40px;
  }
  .MenuItems-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    gap: 3px;
  }

  /* Footer Styles */
  .Brandfooter {
    background-color: #f8f8f8;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: -65px;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin: 0;
  }

  .menu-item {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
  }

  .menu-item:hover {
    color: #007bff; /* Example hover effect */
  }
  .nopolicy {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
  }
  .catSectionIn {
    flex-shrink: 0;
    min-width: 70px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2px 10px;
    border: 1px solid rgb(184, 178, 202);
    border-radius: 50px; /* Oval shape */
    transition: border-color 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
  }
  .chipBtn {
    padding: 5px 10px;
    border: 1px solid var(--PRIMARY_BLUE);
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
  }
  .chipBtn[isselected='true'] {
    background-color: var(--DULL_BLUE);
  }
  .chipBtn[isactive='false'] {
    border: 1px dashed gray;
    color: grey !important;
  }
  .colorChip {
    box-shadow: 0px 0px 3px 0px gray;
    width: 13px;
    height: 23px;
    border: 2px solid #fff;
    outline: 0;
  }
  .colorChip[isselected='true'] {
    outline: 2px solid var(--PRIMARY_BLUE);
  }
  .colorChip[isactive='false'] {
    outline: 2px dashed grey;
    border: 2px solid #fff;
    opacity: 0.5;
  }
  .productsMainContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 8px;
  }
  .productContainer {
    margin: 7px;
    margin-top: 5px;
    width: calc((var(--WIDTH) / 2) - 22px);
  }
  .productImg {
    width: 100%;
    height: 234px;
    border-radius: 7px;
    object-fit: contain;
    background-color: #fff;
    box-shadow: var(--SHADOW);
  }
  .addToBagContainerInList {
    position: absolute;
    width: -webkit-fill-available;
    background: rgba(0, 0, 0, 0.5);
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    bottom: 4px;
  }
  .qtyInput {
    width: 100%;
    border: none;
    outline: none;
    text-align: center;
    border-radius: 4px;
  }
  .qtyInput:focus {
    border: 0.5px solid;
  }
  .nonRestaurantInput {
    background-color: transparent;
    border-radius: 25px;
    margin: 0px 10px;
    color: white;
    padding-block: 3px;
    border: 1px solid white;
  }
  .nonRestaurantInput:focus {
    border: 1px solid white;
  }
  .custom-product-section-container {
    width: full;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
  }

  .product-section {
    max-width: 1300px;
    height: full;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .ps-first-row {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    .first-row-img {
      width: 100%;
      display: block;
      object-fit: cover;
      height: 400px;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
      color: #fff;
      text-align: center;
      .view-button {
        margin-top: 10px;
        padding: 8px 16px;
        background: transparent;
        border: 2px solid #fff;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
  }

  .second-row-img-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    .second-row-img {
      width: 100%;
      display: block;
      object-fit: cover;
      height: 295px;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
      color: #fff;
      text-align: center;
      .view-button {
        margin-top: 10px;
        padding: 8px 16px;
        background: transparent;
        border: 2px solid #fff;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          background: #fff;
          color: #000;
        }
      }
    }
  }

  .ps-second-row {
    display: flex;
    gap: 10px;
  }

  .second-row-img {
    height: 295px;
    width: 295px;
    background-color: #e9e8e8;
    border-radius: 10px;
  }

  .filter-name {
    margin-bottom: 5px;
  }

  .grid-layout {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    justify-content: center;
    justify-self: center;

    .grid-item {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }

    .grid-img {
      width: 100%;
      height: 200px;
      display: block;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .view-button {
      background: transparent;
      border: 2px solid white;
      color: white;
      padding: 6px 12px;
      cursor: pointer;
      transition: 0.3s ease;
      border-radius: none !important;
      height: 30px;
      &:hover {
        background: white;
        color: black;
      }
    }
  }

  .non-image-product-section {
    height: full;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .custom-product-section-container-no-image {
    width: full;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .product-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    overflow-x: auto;
    max-width: 350px;
    padding: 10px;
  }

  .filter-product-image {
    background-color: #e9e8e8;
    height: 300px;
    width: 300px;
  }

  .shopByBrands {
    padding: 10px 20px 10px 20px;

    .brandRow {
      gap: 15px;
      overflow-x: auto;
    }

    .brand {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .logo {
        border-radius: 5px;
        width: 60px;
        height: 60px;
        margin-bottom: 5px;
      }

      .name {
        font-weight: bold;
      }
    }
  }

  .policy-container {
    padding-top: 50px;
    margin-bottom: 180px;
    width: 100%;
    display: flex;
  }
  .sidebar-menu {
    flex: 1;
    max-width: 25%;
    padding: 20px;
  }
  .category-item {
    margin: 10px;
    padding: 10px;
    gap: 5px;
    flex: 0 0 auto; /* Ensure items do not shrink and maintain their size */
    box-sizing: border-box;
    text-align: center; /* Center-align the text */
  }
  .menu-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    text-align: left;
  }
  .menu-button:hover {
    transform: scale(0.95);
  }

  .whatsAppIcon {
    position: sticky;
    bottom: 0px;
    text-align: right;
    width: fit-content;
    margin-left: auto;
    z-index: 1;
    padding: 10px 10px;
  }

  .products-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    padding: 10px;
    width: 90%;
  }

  .product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80%;
    height: 60%;
  }

  .product-image {
    width: 100%;
    height: 250px;
    border-radius: 7px;
    overflow: cover;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }

  .product-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .product-info-outside {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 2px;
  }

  .product-name {
    font-size: 1.2em;
    font-weight: bold;
  }

  .product-description {
    font-size: 1em;
    color: #777;
  }

  /* Ultra-small devices (below 360px) */
  @media (max-width: 360px) {
    .brandlayout {
      padding-left: 5px;
      padding-right: 5px;
    }
    .normalStoreImg {
      width: 100%;
      height: 200px; /* Further reduce height for very small screens */
    }
    .BrandName {
      width: 150px;
      font-size: 14px; /* Adjust text size */
    }
    .row.cursor {
      gap: 0px;
    }
    .menu-item {
      font-size: 12px; /* Smaller font for footer links */
      padding: 5px 10px;
    }
  }

  /* Mobile screens (up to 480px) */
  @media (max-width: 480px) {
    .brandlayout {
      padding-left: 10px;
      padding-right: 10px;
    }
    .burger-menu {
      display: block; /* Show burger menu on small screens */
    }
    .MenuItems {
      display: none; /* Hide on mobile */
    }
    .normalBrandImg {
      width: 100%;
      height: 250px; /* Smaller height for mobile */
    }
    .BrandName {
      width: 160px;
    }
    .row.cursor {
      gap: 0px; /* No gap on small screens */
    }
    .footer-menu {
      display: flex;
      flex-wrap: wrap;
    }
    .footer-content {
      flex-direction: column;
    }

    .menu-item {
      padding: 5px 10px;
    }
  }
}
