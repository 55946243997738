#Home {
  user-select: none;
  .topBar {
    padding: 13px 20px;
    border-radius: 0px;
    position: fixed;
    width: var(--WIDTH);
    box-sizing: border-box;
  }

  .logo {
    width: 90px;
    object-fit: contain;
    cursor: pointer;
  }

  .bannerContainer {
    text-align: center;
    margin: 20px 0px;
  }

  .banner {
    width: calc(100% - 40px);
    border-radius: 15px;
  }

  .featureContainer {
    margin: 20px 20px 0;
  }
  .featureContainerJackpot {
    margin: 20px 20px 0;
  }

  .feature {
    margin: 0px 5px;
    padding: 15px;
    border-radius: 12px;
  }

  .featureJackpot {
    margin: 0px 5px;
    border-radius: 12px;
  }

  .featureIcon {
    width: fit-content;
    margin: auto auto 10px;
    padding: 10px 10px 6px;
    border-radius: 100px;
    background-color: #e3f2fd;
  }

  .itemContainer {
    margin: 0px 5px;
    text-align: center;
    width: calc((100% - 40px) / 4);
  }

  .policyContainer {
    cursor: pointer;
    padding: 20px 20px 30px;
  }

  .infoIcon {
    font-size: 11px;
    color: var(--PRIMARY_BLUE);
    margin: 0px 3px;
  }

  .headerSection {
    padding: 30px 20px 0px;
  }

  .myntVal {
    font-size: 24px !important;
    color: var(--NAVY_BLUE) !important;
    font-family: var(--BOLD_FONT) !important;
  }

  .offer {
    padding: 20px 3px 5px;
    display: flex;
    overflow-x: scroll;
  }
  .homeContestCard {
    width: calc(var(--WIDTH) - 86px);
    margin-right: 10px;
  }
  .homeContestCardjackpot {
    width: calc(var(--WIDTH) - 180px);
    border-radius: 8px;
  }

  .contestImage {
    object-fit: cover;
    height: calc((var(--WIDTH) - 86px) / 2);
    width: calc(var(--WIDTH) - 86px);
  }
  .contestImageJackpot {
    object-fit: contain;
    height: calc((var(--WIDTH) - 86px) / 2);
    width: calc(var(--WIDTH) - 180px);
    padding-top: 20px;
  }
  .cardWrapper {
    flex: 0 0 auto;
  }
  .scrollingText {
    white-space: nowrap;
    overflow: hidden;
    animation: scroll 5s linear infinite;

    @keyframes scroll {
      from {
        transform: translateX(calc(var(--WIDTH) - 86px));
      }
      to {
        transform: translateX(-100%);
      }
    }
  }
  .all {
    margin: auto -20px auto 0px;
    background-color: rgb(245, 240, 240);
    border-radius: 50px;
    padding: 8px;
  }
  .storeTypeBanner {
    height: 150px;
    margin-right: -20px;
  }
  .dealBannerContainer {
    width: calc(var(--WIDTH) - 50px);
    border-radius: 12px;
    overflow: hidden;
    margin: auto;
  }
  .dealBannerImg {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
  }
  @media screen and (max-width: 350px) {
    .storeTypeBanner {
      height: 130px;
    }
  }
  .expiredContainer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.75);
  }

  .listStatusContainer {
    right: 0px;
    top: 0px;
    padding: 3px;
    position: absolute;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  .listStatus {
    margin: 0px 3px;
    font-size: 13px !important;
    font-weight: 600 !important;
  }
  .statusRed {
    background-color: rgb(238, 22, 33);
    border-bottom-left-radius: 10px;
    border-top-right-radius: 6px;
    z-index: 0;
  }
  .statusGreen {
    background-color: rgb(40, 203, 40);
    border-bottom-left-radius: 10px;
    border-top-right-radius: 6px;
    z-index: 0;
  }
  .addToBagContainerInList {
    position: absolute;
    width: -webkit-fill-available;
    background: rgba(0, 0, 0, 0.5);
    // background-color: rgb(16, 172, 97);
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    bottom: 4px;
  }
  .quantityContainer {
    width: 100px;
    margin: 0px 10px;
    border-radius: 25px;
    border: 1px solid grey;
    text-align: center !important;
    user-select: none;
  }

  #AddPromoBag {
    .cartBtnContainer {
      position: fixed;
      bottom: 0;
      width: var(--WIDTH);
      margin: auto;
      z-index: 1;
      background-color: #fff;
      padding: 15px 10px;
      box-shadow: 0px 0px 12px -7px black;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      box-sizing: border-box;
    }
    .cartBtn {
      padding: 10px;
      border-radius: 15px;
      margin: auto;
      background-color: var(--PRIMARY_BLUE);
    }
    .cartNum {
      margin-left: 6px;
      padding: 0px 6px;
      border-radius: 10px;
    }
    .nonRestaurantInput {
      background-color: #0000;
      border: 1px solid #fff;
      border-radius: 25px;
      color: #fff;
      margin: 0 10px;
      padding-block: 3px;
    }
    .qtyInput {
      border: none;
      border-radius: 4px;
      outline: none;
      text-align: center;
      width: 100%;
    }
  }
}
