.sidebar {
  width: 230px;
  background-color: white;
  border-right: 1px solid #ebebeb;
  padding: 30px 0px 0px 15px;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
  scrollbar-color: #3076b5;
  /* top: 45px; */
  top: 0px;
  /* z-index: 100; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.myntVal {
  font-size: 24px !important;
  color: var(--NAVY_BLUE) !important;
  font-family: var(--BOLD_FONT) !important;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  box-sizing: border-box;
}

.sidebar li {
  /* margin: 5px 0; */
  margin-right: 20px;
  font-size: 14px;
  padding: 10px 0px 10px 15px;
  display: flex;
  color: #4a4a4a;
  align-items: center;
  box-sizing: border-box;
}

.activeTab .icon {
  color: #3076b5;
  font-size: 24px;
  fill: #3076b5;
}

.sidebar .activeTab a {
  color: #3076b5;
}

.sidebar li:hover {
  color: #3076b5;
  font-size: 15px;
  cursor: pointer;
  transition: all;
  fill: #3076b5;
  /* transition-duration: 0.4s; */
}

.sidebar li:hover .icon {
  width: 22px;
  height: 22px;
}

.activeTab {
  border-radius: 5px;
  /* outline: .1px solid #d2e9fd; */
  color: #3076b5;
  background-color: #f0f8fd;
  cursor: pointer;
}

.sidebar a {
  text-decoration: none;
  margin-left: 10px;
  color: #4a4a4a;
  font-family: MynyfyMedium;
}

.sidebar li:hover a {
  color: #3076b5;
}

.activeTab a .new-orders-count {
  color: #3076b5;
  background-color: transparent;
}

.notification-badge {
  padding: 2px 5px;
}

.new-orders-count {
  margin-left: 80px;
  border-radius: 2px;
  background-color: #e4ffe8;
  padding: 0 5px 0 5px;
  color: #009d1d;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9); /* Scale up */
  }
  100% {
    transform: scale(1); /* Back to original size */
  }
}

/* Apply the animation on click */
.bouncy {
  animation: bounce 0.3s ease-in-out; /* Adjust duration as needed */
}
