.videoPlayBtn {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  color: white;
  font-size: 55px;
  transform: translate(-50%, -50%);
}

#jackpotNew{
  padding-top: 5px;
  .headerjackpot{
    display:flex;
    padding: 10px 10px;
  }
  .cardWrapper {
  flex: 0 0 auto; 
}
.offerJackpot{
  margin:40px 10px;
}
.homeContestCardjackpot{
     width: calc(var(--WIDTH) - 180px);
     background-color:rgb(228, 252, 250);
     box-sizing: border-box;
  }
.contestImageJackpot{
  object-fit: contain;
   border-radius: 10px;
    height:calc(var(--WIDTH) - 180px);
    width: calc(var(--WIDTH) - 180px);
    display:block;
  
}
.featureJackpot {
    margin: 0px 5px;
    border-radius: 12px;

  }
  .featureContainerJackpot{
    margin: 20px 20px 0;
  }

  .scrollingText{
  white-space: nowrap;
  overflow: hidden;
  animation: scroll 5s linear infinite; 

  @keyframes scroll {
  from {
    transform: translateX(calc(var(--WIDTH) - 86px));
  }
  to {
    transform: translateX(-100%);
  }
  }
  }

  .pastContainer{
    display: flex;
    background-color: rgb(206, 210, 209);
    flex-direction: column;
  }
  .inactiveContainer{
    display: flex;
    flex-wrap:wrap;
    padding-bottom: 30px;
    padding: 5px;
  }
  .inactiveCard{
  width: calc((var(--WIDTH) - 30px) / 2);
    box-shadow: var(--SHADOW);
    background-color: #fff;
    border-radius: 6px;
    margin: 5px;
    display: flex;
    flex-direction: column;
  }
  .contestPastImg{
    width: calc(((var(--WIDTH) - 30px) / 2) - 4px) !important;
    height: calc(((var(--WIDTH) - 30px) / 2) - 4px) !important;
    object-fit: contain;
    border-radius: 6px;
    display: block;
    margin: 2px;
  }
  .noImgContainer{
     border-radius: 200px;
    border: 2px solid #e2e2e2;
    width: calc(((var(--WIDTH) - 30px) / 2) - 20px);
    height: calc(((var(--WIDTH) - 30px) / 2) - 20px);
    justify-content: center;
    align-items: center;
    display: flex;
    margin: auto;

  }
.myntsContainer{
  background-color: rgb(188, 249, 245);
  border-radius: 20px;
  padding: 3px;
}

}