#joinByScan {
  .container {
    padding: 15px;
  }

  .contestCard {
    padding: 8px;
    border-radius: 10px;
  }

  .contestImg {
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 9px;
  }

  .contestDetails {
    margin: 10px;
  }

  .brandLogo{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: contain;
  }
}